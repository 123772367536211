import React, { useState } from 'react';

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      question: 'Do I need anything to join this program?',
      answer: 'No formal qualifications needed—just bring yourself and a willingness to learn!',
    },
    {
      question: 'When are the live calls and guest sessions?',
      answer:
        'The live group calls will take place every Sunday at 03:00 PM IST. The full program schedule will be shared during the onboarding call.',
    },
    {
      question: "What should I do before the workshop starts?",
      answer:
        "Be sure to arrive 10 minutes early and have a notebook and pen ready to note down key points during the workshop.",
    },
    {
      question: 'When will I get my LevelUp Certificate?',
      answer:
        'You will be eligible to receive a Certificate of Completion from LevelUp once you successfully complete 80% of the course, including all learning modules and project submissions.',
    },
    {
      question: 'How much time will the program take each week?',
      answer:
        'Be ready to spend 3-4 hours each week for 24 weeks! The 90-minute live sessions will be in the evenings (IST), with exciting Guest Workshops and Jamming Sessions along the way.',
    },
    {
      question: "What’s included in the program?",
      answer:
        "With your tuition, you will get lifetime access to pre-study materials, recordings of live sessions, and a private online community to connect with your batchmates. You’ll keep access to all the program materials and community even after the program ends.",
    },
    // {
    //   question: 'When will I get my Levelup Certificate?',
    //   answer:
    //     'Individuals who successfully complete 80% of the course work i.e. cover all learning modules & project submissions are eligible to receive a Certificate of Completion from Levelup.',
    // },
  ];

  return (
    <section className="py-16 pt-10 bg-gray-200 font-sans" data-aos="fade-up">
      <div className="container mx-auto px-6 md:px-12">
        <div className="text-center mb-12" >
        <h2 className="text-[26px] md:text-[34px] text-center text-black mb-8 leading-tight">
        Frequently <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent"> Asked Questions
</span>
          </h2>
        </div>

        {/* FAQ Section */}
        <div className="space-y-6">
          {faqs.map((faq, index) => (
            <div key={index} className="w-full max-w-3xl mx-auto">
              <div
                className="flex justify-between items-center p-5 bg-white rounded-lg shadow-md cursor-pointer hover:bg-gray-100"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <div className="text-lg font-semibold text-black">{faq.question}</div>
                <div
                  className={`transform transition-transform duration-300 ${openIndex === index ? 'rotate-180' : ''}`}
                >
                  <svg
                    className="w-6 h-6 text-black"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
              <div
                className={`transition-all ease-in-out duration-500 overflow-hidden ${
                  openIndex === index ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                }`}
              >
                <div className="p-5 bg-gray-50 text-gray-700 rounded-lg mt-2">{faq.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
