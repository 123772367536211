import React from "react";
import { Link } from "react-router-dom";
import RegisterButton from "./RegisterButton";

const YourPath = () => {
  return (
    <section className="py-16 bg-gray-100 font-sans">
      <div className="container mx-auto px-6 lg:px-12 max-w-[70rem] text-center">
        {/* Heading */}
        <h2 className="text-[26px] md:text-[34px] text-center text-black mb-5 leading-tight">
          <span className="font-bold text-[35px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
          Reach 
          </span>
          &nbsp;Your Peak
        </h2>

        {/* Subheading */}
        <p className="text-lg md:text-xl text-gray-600 mb-12">
        Unlock your success with strategies built for winners. Here’s what makes you stand out.
        </p>

        {/* List */}
        <div className="mb-12">
          <ol className="list-decimal font-bold list-inside text-left text-black text-base md:text-lg space-y-4 mx-auto max-w-2xl">
            <li>
              <strong className="text-black">Designed for Success:</strong> <br /> <span className="text-[#4b5563] font-[400]">More than just tools – it’s your roadmap to outpace the competition.</span>
            </li>
            <li>
              <strong className="text-black">Expert-Led Strategies:</strong> <br /> <span className="text-[#4b5563] font-[400]">Learn from top industry leaders with tactics proven to increase job success by 70%.</span>
            </li>
            <li>
              <strong className="text-black">Customized Approach:</strong> <br /> <span className="text-[#4b5563] font-[400]">Tailor the plan to match your unique strengths, potentially doubling your interview chances.</span>
            </li>
            <li>
              <strong className="text-black">Skill Growth:</strong> <br /> <span className="text-[#4b5563] font-[400]">Tap into a library of resources for ongoing skill development, increasing employability by 40%.</span>
            </li>
            <li>
              <strong className="text-black">Strategic Networking:</strong> <br /> <span className="text-[#4b5563] font-[400]">Join a community where connections lead to 80% of hidden job opportunities.</span>
            </li>
          </ol>
        </div>

        {/* Call to Action */}
        <div className="mb-8">
          <p className="text-lg md:text-xl font-medium text-gray-700">
            <span className="italic">
            Take action now, and your future self will be grateful!
            </span>
          </p>
        </div>

        {/* Button */}
        {/* <Link
                to="#"
                className="cta-button text-white py-[15px] px-[20px] md:px-[50px] rounded-xl hover:bg-blue-700 transition-all text-[18px] md:text-[24px] w-fit"
            >
                Enroll Now at <span className="line-through">₹ 6000</span> 299/$4
        </Link> */}
        <RegisterButton/>
      </div>
    </section>
  );
};

export default YourPath;
