// import React, { useState, useEffect } from "react";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import { Link } from "react-router-dom";
// import { FaCheck } from "react-icons/fa"; // Import Check Icon
// import AOS from "aos";
// import "aos/dist/aos.css";

// const AIExpertForm = () => {
//   const [phone, setPhone] = useState("");

//   useEffect(() => {
//     AOS.init({
//       duration: 1200,
//       easing: "ease-in-out",
//       once: true,
//       mirror: false,
//     });
//   }, []);

//   return (
//     <section className="py-12 bg-gray-100 min-h-screen font-sans">
//       <div className="container mx-auto px-4 max-w-6xl">
//         {/* Logo Section */}
//         <div className="flex justify-center">
//           <Link to="/">
//             <img
//               src="/images/NFA-Logo.png"
//               alt="Logo"
//               className="bg-gradient w-[220px] p-3 py-2 rounded mb-12 mt-5"
//               data-aos="fade-down" // AOS fade down for logo
//             />
//             </Link>
//         </div>

//         {/* Main Section */}
//         <div className="bg-white rounded-xl shadow-lg p-4 md:p-12">
//           <h2 className="text-[26px] md:text-[34px] text-center text-black mb-8 leading-tight" data-aos="fade-up">
//             Wait! You’re Just One Step Away From Becoming an 
//             <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
//               <br />AI Expert and Doubling Your Income
//             </span> 
//           </h2>

//           <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//             {/* Left Section - Bonuses */}
//             <div
//               className="p-6 "
//               data-aos="fade-right"
//             >
//               <h3 className="text-[24px] md:text-[36px] font-bold text-black mb-6">
//                 Bonuses <span className=" text-[20px]">(Worth ₹15,000)</span>
//               </h3>
//               <ul className="space-y-6">
//                 <li className="flex items-center space-x-4">
//                   <div className="flex-shrink-0">
//                     <FaCheck className="text-text-secondry text-[24px] bg-background-primary bg-opacity-10 p-[5px] rounded-full" />
//                   </div>
//                   <div>
//                     <p className="text-[16px] md:text-[18px] font-medium text-gray-800">
//                       Certified AI & ChatGPT Expert
//                       <span className="text-black font-bold">
//                       &nbsp;(Priceless)
//                       </span>
//                     </p>
//                   </div>
//                 </li>
//                 <li className="flex items-center space-x-4">
//                   <div className="flex-shrink-0">
//                     <FaCheck className="text-text-secondry text-[24px] bg-background-primary bg-opacity-10 p-[5px] rounded-full" />
//                   </div>
//                   <div>
//                     <p className="text-[16px] md:text-[18px] font-medium text-gray-800">
//                       150+ Valuable ChatGPT Prompts
//                       <span className="text-black font-bold">
//                       &nbsp;(₹5,000)
//                       </span>
//                     </p>
//                   </div>
//                 </li>
//                 <li className="flex items-center space-x-4">
//                   <div className="flex-shrink-0">
//                     <FaCheck className="text-text-secondry text-[24px] bg-background-primary bg-opacity-10 p-[5px] rounded-full" />
//                   </div>
//                   <div>
//                     <p className="text-[16px] md:text-[18px] font-medium text-gray-800">
//                       50+ AI Tools with Real-Life Use Cases
//                       <span className="text-black font-bold">
//                       &nbsp;(₹3,000)
//                       </span>
//                     </p>
//                   </div>
//                 </li>
//                 <li className="flex items-center space-x-4">
//                   <div className="flex-shrink-0">
//                     <FaCheck className="text-text-secondry text-[24px] bg-background-primary bg-opacity-10 p-[5px] rounded-full" />
//                   </div>
//                   <div>
//                     <p className="text-[16px] md:text-[18px] font-medium text-gray-800">
//                       20 Productivity Hacks to 10X Efficiency
//                       <span className="text-black font-bold">
//                       &nbsp;(₹3,000)
//                       </span>
//                     </p>
//                   </div>
//                 </li>
//                 <li className="flex items-center space-x-4">
//                   <div className="flex-shrink-0">
//                     <FaCheck className="text-text-secondry text-[24px] bg-background-primary bg-opacity-10 p-[5px] rounded-full" />
//                   </div>
//                   <div>
//                     <p className="text-[16px] md:text-[18px] font-medium text-gray-800">
//                       Workbook for Masterclass Topics
//                       <span className="text-black font-bold">
//                       &nbsp;(₹2,000)
//                       </span>
//                     </p>
//                   </div>
//                 </li>
//                 <li className="flex items-center space-x-4">
//                   <div className="flex-shrink-0">
//                     <FaCheck className="text-text-secondry text-[24px] bg-background-primary bg-opacity-10 p-[5px] rounded-full" />
//                   </div>
//                   <div>
//                     <p className="text-[16px] md:text-[18px] font-medium text-gray-800">
//                       100+ Viral Post Ideas for LinkedIn 
//                       <span className="text-black font-bold">
//                       &nbsp;(₹2,000)
//                       </span>
//                     </p>
//                   </div>
//                 </li>
//               </ul>
//             </div>

//             {/* Right Section - Form */}
//             <form
//               className="border border-gray-150 rounded-lg  p-6"
//               data-aos="fade-left"
//             >
//               <div className="mb-6">
//               <label
//                 htmlFor="full_name"
//                 className="block text-gray-700 text-sm font-semibold mb-2"
//               >
//                 Full Name <span className="text-red-500">*</span>
//               </label>
//               <input
//                 type="text"
//                 id="full_name"
//                 name="full_name"
//                 placeholder="Enter your full name"
//                 className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 shadow-sm hover:shadow-md"
//                 required
//               />
//             </div>

//             <div className="mb-6">
//               <label
//                 htmlFor="email"
//                 className="block text-gray-700 text-sm font-semibold mb-2"
//               >
//                 Email <span className="text-red-500">*</span>
//               </label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 placeholder="Enter your email address"
//                 className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 shadow-sm hover:shadow-md"
//                 required
//               />
//             </div>

//             <div className="mb-6">
//               <label
//                 htmlFor="phone"
//                 className="block text-gray-700 text-sm font-semibold mb-2"
//               >
//                 Phone (WhatsApp) <span className="text-red-500">*</span>
//               </label>
//               <div className="relative">
//                 <PhoneInput
//                   country={"in"}
//                   value={phone}
//                   onChange={(value) => setPhone(value)}
//                   inputStyle={{
//                     width: "100%",
//                     padding: "12px 14px 12px 50px", // Adjust padding to fit the flag icon
//                     border: "1px solid #d1d5db",
//                     borderRadius: "8px",
//                     fontSize: "16px",
//                     color: "#333",
//                     backgroundColor: "#fff",
//                     boxShadow: "0 4px 6px rgba(0, 0, 0, 0.05)",
//                     transition: "box-shadow 0.8s ease",
//                     height: "50px",
//                   }}
//                   containerStyle={{
//                     position: "relative",
//                   }}
//                   buttonStyle={{
//                     border: "none",
//                     backgroundColor: "transparent",
//                     padding: "0",
//                     borderRadius: "8px 0 0 8px",
//                     position: "absolute",
//                     left: "10px",
//                     top: "",
//                   }}
//                   dropdownStyle={{
//                     borderRadius: "8px",
//                     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                     overflow: "",
//                   }}
//                   className="focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:shadow-md"
//                   required
//                 />
//               </div>
//             </div>

//             <div className="mb-6">
//               <label
//                 htmlFor="profession"
//                 className="block text-gray-700 text-sm font-semibold mb-2"
//               >
//                 Profession <span className="text-red-500">*</span>
//               </label>
//               <select
//                 id="profession"
//                 name="profession"
//                 className="w-full px-4 py-3 border border-gray-300 rounded-md text-gray-800 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-300 shadow-sm hover:shadow-md h-[50px]"
//                 required
//               >
//                 <option value="" className="text-gray-400">
//                   Select Profession
//                 </option>
//                 <option value="IT Professional">IT Professional</option>
//                 <option value="Business Owner">Business Owner</option>
//                 <option value="Aspiring Business Owner">
//                   Aspiring Business Owner
//                 </option>
//                 <option value="Student">Student</option>
//                 <option value="Designer">Designer</option>
//                 <option value="Digital Marketer">Digital Marketer</option>
//                 <option value="Others">Others</option>
//               </select>
//             </div>



//               <div>
//                 <button
//                   type="submit"
//                   className="cta-button w-full bg-gradient-to-r from-green-400 to-blue-500 text-white font-semibold py-3 rounded-lg shadow-md hover:shadow-lg transition duration-300"
//                 >
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AIExpertForm;



import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "./Loader";
import { toast } from "react-toastify";
import axios from "axios";

const AIExpertForm = () => {
  const [apiIsLoading, setapiIsLoading] = useState(false)
  const navigate = useNavigate()
  useEffect(() => {
    AOS.init({
      duration: 1200,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });
  }, []);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    profession: Yup.string().required("Profession is required"),
  });

  const initialValues = {
    full_name: "",
    email: "",
    phone: "",
    profession: "",
  };

  const handleSubmit = async(values, { resetForm }) => {
    console.log("Form submitted with values:", values);
    try{
      let data = {
        name:values.full_name,
        email:values.email,
        phone:values.phone,
        profession:values.profession
        
      }
      setapiIsLoading(true)
      // const res = await axios.post(`${process.env.REACT_APP_API_PORT}api/user/patient/testcontactus/create`,data)
      const res = await axios.post(`${process.env.REACT_APP_API_PORT}user_registration`,data)
      setapiIsLoading(false)
      if(res.data.status===true){
        localStorage.setItem("isRegistered",true)
        toast.success(res.data.message)
        navigate("/thank-you")
      }
    }
    catch(err){
      setapiIsLoading(false)
      console.log(err)
      if (err.response?.data?.status === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <>
    <Loader isLoading={apiIsLoading} />
    <section className="py-12 bg-gray-100 min-h-screen font-sans">
      <div className="container mx-auto px-4 max-w-6xl">
        {/* Logo Section */}
        <div className="flex justify-center">
          <Link to="/">
            <img
              src="/images/NFA-Logo.png"
              alt="Logo"
              className="bg-gradient w-[220px] p-3 py-2 rounded mb-12 mt-5"
              data-aos="fade-down"
            />
          </Link>
        </div>

        {/* Main Section */}
        <div className="bg-white rounded-xl shadow-lg p-4 md:p-12">
          <h2
            className="text-[26px] md:text-[34px] text-center text-black mb-8 leading-tight"
            data-aos="fade-up"
          >
            Wait! You’re Just One Step Away From Becoming an{" "}
            <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
              <br />
              AI Expert and Doubling Your Income
            </span>
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Section - Bonuses */}
            <div className="p-6" data-aos="fade-right">
              <h3 className="text-[24px] md:text-[36px] font-bold text-black mb-6">
                Bonuses <span className="text-[20px]">(Worth ₹15,000)</span>
              </h3>
              <ul className="space-y-6">
                {[
                  { text: "Certified AI & ChatGPT Expert", price: "Priceless" },
                  { text: "150+ Valuable ChatGPT Prompts", price: "₹5,000" },
                  { text: "50+ AI Tools with Real-Life Use Cases", price: "₹3,000" },
                  { text: "20 Productivity Hacks to 10X Efficiency", price: "₹3,000" },
                  { text: "Workbook for Masterclass Topics", price: "₹2,000" },
                  { text: "100+ Viral Post Ideas for LinkedIn", price: "₹2,000" },
                ].map((item, index) => (
                  <li key={index} className="flex items-center space-x-4">
                    <div className="flex-shrink-0">
                      <FaCheck className="text-text-secondry text-[24px] bg-background-primary bg-opacity-10 p-[5px] rounded-full" />
                    </div>
                    <div>
                      <p className="text-[16px] md:text-[18px] font-medium text-gray-800">
                        {item.text}
                        <span className="text-black font-bold">
                          &nbsp;({item.price})
                        </span>
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {/* Right Section - Form */}
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue }) => (
                <Form
                  className="border border-gray-150 rounded-lg p-6"
                  data-aos="fade-left"
                >
                  {/* Full Name */}
                  <div className="mb-6">
                    <label htmlFor="full_name" className="block text-gray-700 text-sm font-semibold mb-2">
                      Full Name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      id="full_name"
                      name="full_name"
                      placeholder="Enter your full name"
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <ErrorMessage name="full_name" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Email */}
                  <div className="mb-6">
                    <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email address"
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Phone */}
                  <div className="mb-6">
                    <label htmlFor="phone" className="block text-gray-700 text-sm font-semibold mb-2">
                      Phone (WhatsApp) <span className="text-red-500">*</span>
                    </label>
                    <PhoneInput
                      country={"in"}
                      onChange={(value) => setFieldValue("phone", value)}
                      inputStyle={{
                        width: "100%",
                        borderRadius: "8px",
                        height: "50px",
                      }}
                    />
                    <ErrorMessage name="phone" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Profession */}
                  <div className="mb-6">
                    <label htmlFor="profession" className="block text-gray-700 text-sm font-semibold mb-2">
                      Profession <span className="text-red-500">*</span>
                    </label>
                    <Field
                      as="select"
                      id="profession"
                      name="profession"
                      className="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="" disabled>
                        Select Profession
                      </option>
                      <option value="IT Professional">IT Professional</option>
                      <option value="Business Owner">Business Owner</option>
                      <option value="Aspiring Business Owner">Aspiring Business Owner</option>
                      <option value="Student">Student</option>
                      <option value="Designer">Designer</option>
                      <option value="Digital Marketer">Digital Marketer</option>
                      <option value="Others">Others</option>
                    </Field>
                    <ErrorMessage name="profession" component="div" className="text-red-500 text-sm mt-1" />
                  </div>

                  {/* Submit Button */}
                  <div>
                    <button
                      type="submit"
                      className="cta-button w-full bg-gradient-to-r from-green-400 to-blue-500 text-white font-semibold py-3 rounded-lg shadow-md hover:shadow-lg"
                    >
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default AIExpertForm;
