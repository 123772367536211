import React from "react";
import { Link } from "react-router-dom";
import { FaWhatsapp, FaShareAlt } from "react-icons/fa";
import RegisterButton from "./RegisterButton";

const CongratulationsSection = () => {
  return (
    <section className="py-12 min-h-screen font-sans">
      <div className="container mx-auto px-4 max-w-[67rem]">
        <div className="flex flex-col items-center text-center space-y-6 md:space-y-12">
        {/* Header Section */}
        <div className="space-y-4">
            {/* Logo Section */}
            <div className="flex justify-center">
            <Link to="/">
                <img
                src="/images/NFA-Logo.png"
                alt="Logo"
                className="bg-gradient w-[220px] p-3 py-2 rounded mb-8 mt-5"
                // AOS fade down for logo
                />
                </Link>
            </div>
            <h2 className="text-[26px] md:text-[34px] text-center text-black mb-8 leading-tight" >
            🎉 <span className="font-bold text-[32px] md:text-[50px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
                 Big Congrats! <br />
                </span> 
                You’ve unlocked access to the FREE AI Masterclass
            </h2>
        </div>

        {/* Live Badge */}
        <div>
            <span className="inline-flex items-center bg-red-600 text-white text-sm font-medium uppercase py-2 px-8 rounded-full shadow-lg tracking-wide animate-pulse">
            🔴 Live Now
            </span>
        </div>

        {/* Email Reminder */}
        <div className="max-w-lg">
            <p className="text-xl text-gray-700 leading-relaxed">
            Connect Now: Join Our VIP WhatsApp Group
            <br></br>Let’s Grow Together – Invite Your Friends Now
            </p>
        </div>

        {/* Buttons Section - Aligned in a row */}
<div className="flex flex-col md:flex-row gap-4 w-full max-w-full m-auto justify-center mx-2">
    <Link
        to="/whatsapp-group"
        className="inline-flex items-center justify-center text-white py-3 px-6 md:px-8 rounded-lg shadow-md bg-gradient-to-r from-green-500 to-teal-600 hover:from-green-600 hover:to-teal-700 transition-all text-lg md:text-xl font-semibold space-x-2"
    >
        <FaWhatsapp className="text-xl md:text-2xl" />
        <span>Join WhatsApp Group</span>
    </Link>
    <Link
        to="/invite-friends"
        className="inline-flex items-center justify-center text-white py-3 px-6 md:px-8 rounded-lg shadow-md bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 transition-all text-lg md:text-xl font-semibold space-x-2"
    >
        <FaShareAlt className="text-xl md:text-2xl" />
        <span>Invite Your Friends</span>
    </Link>
</div>

        {/* Video Section */}
        <div className="w-full max-w-3xl">
            <div className="relative overflow-hidden rounded-lg">
            <iframe
                src="https://player.vimeo.com/video/920320933?byline=0&fun=0&portrait=0&title=0"
                height=""
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                className="w-full h-[200px] md:h-[400px]"
                title="AI Masterclass Video"
            />
            </div>
        </div>

        {/* Career Boost Section */}
        {/* <div className="space-y-3 max-w-3xl">
            <h2 className="text-[26px] md:text-4xl font-bold text-black leading-tight">
            🚀 Double Your Salary in 90 Days
            </h2>
            <p className="text-lg text-gray-600">
            Unlock career growth with AI-enhanced resumes and job preparation
            techniques. Start transforming your future today!
            </p>
        </div> */}

        {/* Closing Section */}
        <div className="space-y-2">
            <h2 className="text-[26px] md:text-3xl font-bold text-black leading-tight">
            Your Road to Victory Starts Right Now
            </h2>
            <p className="text-gray-600 mt-3">
            Don’t Wait—Take the First Step Toward Success
            </p>
        </div>

        <div>
        {/* <a
            href="#"
            className="cta-button text-white py-[15px] px-[20px] md:px-[50px] rounded-xl hover:bg-blue-700 transition-all text-[18px] md:text-[24px] w-fit"
        >
            Enroll Now at <span className="line-through">₹ 6000</span> 299/$4
        </a> */}
        <RegisterButton/>
        </div>
        </div>
        </div>
        </section>
  );
};

export default CongratulationsSection;
