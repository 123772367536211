import React from "react";
import RegisterButton from "./RegisterButton";

const ThreeColumnSection = () => {
  return (
    <section className="py-12 bg-gray-100 font-sans">
      <div className="container mx-auto px-4 max-w-[67rem]">
        <h2 className="text-[26px] md:text-[34px] text-center text-black mb-8 leading-tight" >
        Turning Job  <br />
         <span className="font-bold text-[28px] md:text-[40px] bg-[linear-gradient(45deg,_#00bf67,_#0068ff)] bg-clip-text text-transparent">
         Hunting into Success 
            </span> 
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* First Box */}
          <div className="relative h-[380px] bg-gray-100 shadow-lg rounded-lg overflow-hidden">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: "url('/images/challenges.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
            <div className="relative z-10 flex flex-col justify-end h-full p-6">
              <h3 className="text-xl font-semibold text-white">Major Roadblock</h3>
              <p className="text-sm text-white mt-2 leading-tight">
              Major Roadblock Shocking but True—Over 80% of Resumes Are Ignored. Only 1 in 10 Make It to the Manager
              </p>
            </div>
          </div>

          {/* Second Box */}
          <div className="relative h-[380px] bg-gray-100 shadow-lg rounded-lg overflow-hidden">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: "url('/images/grads.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
            <div className="relative z-10 flex flex-col justify-end h-full p-6">
              <h3 className="text-xl font-semibold text-white">Grads Facing Job Market Hurdles</h3>
              <p className="text-sm text-white mt-2 leading-tight">
              Grads Facing Job Market Hurdles
              Finding decent jobs is an uphill battle for new graduates today.

              </p>
            </div>
          </div>

          {/* Third Box */}
          <div className="relative h-[380px] bg-gray-100 shadow-lg rounded-lg overflow-hidden">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: "url('/images/takeover.png')",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent"></div>
            <div className="relative z-10 flex flex-col justify-end h-full p-6">
              <h3 className="text-xl font-semibold text-white">The AI Revolution</h3>
              <p className="text-sm text-white mt-2 leading-tight">
              The AI Revolution
By 2025, AI could replace 85 million jobs. Stay ahead in the race!

              </p>
            </div>
          </div>
        </div>
        {/* Career Boost Section */}
        <div className="flex flex-col items-center text-center space-y-12 mt-10">
            <div className="space-y-3 max-w-3xl">
                <h2 className="text-[26px] md:text-4xl font-bold text-black">
                Don’t Fear...
                </h2>
                <p className="text-lg text-gray-600">
                In a world of fleeting chances, are you primed for success?
                </p>
            </div>

            {/* Closing Section */}
            <div className="space-y-2">
                <h2 className="text-[26px] md:text-3xl font-bold text-black leading-tight">
                Empower Your Future with the Dream Job Achiever Pack – your secret to crushing the tough job hunt.
                </h2>
            </div>
            <div>
            {/* <a
                href="#"
                className="cta-button text-white py-[15px] px-[20px] md:px-[50px] rounded-xl hover:bg-blue-700 transition-all text-[18px] md:text-[24px] w-fit"
            >
                Enroll Now at <span className="line-through">₹ 6000</span> 299/$4
            </a> */}
            <RegisterButton/>
            </div>
        </div>

      </div>
    </section>
  );
};

export default ThreeColumnSection;
