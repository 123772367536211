import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import MasterclassRegistrationPage from './pages/MasterclassRegistrationPage';
import ThankYou from './pages/ThankYou';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsService from './pages/TermsService';
import CancellationRefundPolicy from './pages/CancellationRefundPolicy';
import ContactUs from './pages/ContactUs';
import ProtectedRegistration from './components/ProtectedRegistration';

function App() {
  return (
    <>
    <ToastContainer/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/masterclass-registration" element={<MasterclassRegistrationPage />} />
      <Route element={<ProtectedRegistration/>}>
      <Route path="/thank-you" element={<ThankYou />} />
      </Route>
      
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-service" element={<TermsService />} />
      <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy />} />
      <Route path="/contact-us" element={<ContactUs />} />
    </Routes>
    </>
  );
}

export default App;
